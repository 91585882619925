<template lang="html">
  <div class="">
    <div  v-if="this.review_box_opened" @click="omdöme_func()"   :style="{width: '100%',zIndex: 2,backgroundColor: 'black',opacity: 0.5,position:'fixed',marginLeft:'0%',marginTop:'0%', height: '100%' }">
    </div>
    <contactform  v-if="checked" :company_id="company_id" :verified="verified" from_page="company"  />

    <div  v-if="this.shown_image_flag" @click="remove_flag_enlarge()"   :style="{width: '100%',zIndex: 4,backgroundColor: 'black',opacity: 0.5,position:'fixed',marginLeft:'0%',marginTop:'0%', height: '100%' }">
    </div>

    <div v-if="this.shown_image_flag && this.w >450" @click="remove_flag_enlarge()" :style="{width: '90%', zIndex:'9',position:'fixed',marginLeft:'4%',marginTop:'2%', height: '82%',background: '#FFFFFF',borderRadius: '30px' }">
      <b-icon icon="x-lg"  :style="{color:'black',marginTop:'3%',marginLeft:'68%', position:'absolute'}" />
      <img  :src="get_image(this.company_id,this.shown_image)"   :style="{height:'80%',marginTop:'4%', width:'50%'}"  alt="image" >
    </div>

    <div v-if="this.shown_image_flag && this.w <=450" @click="remove_flag_enlarge()" :style="{width: '90%', zIndex:'9',position:'fixed',marginLeft:'4%',marginTop:'2%', height: '72%',background: '#FFFFFF',borderRadius: '30px' }">
      <b-icon icon="x-lg"  :style="{color:'black',marginTop:'5%',marginLeft:'88%', position:'absolute'}" />
      <img  :src="get_image(this.company_id,this.shown_image)"   :style="{height:'50%',marginTop:'9%', width:'98%'}"  alt="image" >
    </div>

    <div v-if="this.w >450" :style="{height:'50px',display:'flex', width:'100%'}">
      <div    @click="to_result()"  :style="{height:'25px',cursor:'pointer',width:'190px', fontSize:'14px',fontWeight:500, marginLeft:'5%',marginTop:'1%',display:'flex',color:'#717171'}">
          <b-icon icon="chevron-left" :style="{marginTop:'2%'}" />  Till resultatsida
      </div>
      <div  v-if="this.verified !=1"
            @click="is_business_func()"
            :style="{height:'25px',textDecoration:'underline', fontSize:'14px',fontWeight:500, marginLeft:'64%',marginTop:'1%',display:'flex',color:'#717171'}">
            Är detta ditt företag?
      </div>
    </div>
    <div v-if="this.w <450" :style="{height:'40px',display:'flex', width:'100%'}">
        <div    @click="to_result()"  :style="{height:'25px',cursor:'pointer',width:'190px', fontSize:'14px',fontWeight:500, marginLeft:'2%',marginTop:'2%',display:'flex',color:'#717171'}">
            <b-icon icon="chevron-left" :style="{marginTop:'2%'}" />  Till resultatsida
        </div>
        <div v-if="this.verified !=1"  @click="is_business_func()"  :style="{height:'25px',textDecoration:'underline',width:'190px', fontSize:'14px',fontWeight:500, marginLeft:'5%',marginTop:'2%',display:'flex',color:'#717171'}">
              Är detta ditt företag?
        </div>
    </div>
    <div  v-if="this.is_business && this.w <450"  :style="{width: '60%',backgroundColor:'white' ,borderRadius: '67px', border: '5px solid #0A6338',color:'#0A6338', zIndex:'6',position:'fixed',marginLeft:'14%',fontSize:'20px', paddingTop:'5%',marginTop:'2%',
              height: '62%'}">
        <div @click="is_business_func()" :style="{color:'#0A6338',cursor:'pointer',marginTop:'-5%' ,marginLeft:'70%'}">
          X
        </div>
        <div :style="{width:'80%', marginLeft:'10%'}">
            Om detta är ditt företag så är du välkommen att skicka ett email till oss på info@enjord.com så hjälper vi er med registreringen
        </div>
        <button type="button" @click="to_mail()" :style="{background: '#0A6338 0% 0%', borderStyle:'none',  marginTop:'15%',color:'white',fontSize:'19px',width: '154px',height: '46px' ,borderRadius: '12px'}" name="button">
          Kontakta oss
        </button>
    </div>

    <div  v-if="this.is_business && this.w >450"  :style="{width: '70%',backgroundColor:'white' ,borderRadius: '67px', border: '5px solid #0A6338',color:'#0A6338', zIndex:'6',position:'fixed',marginLeft:'10%',fontSize:'25px', paddingTop:'5%',marginTop:'2%', height: '42%'}">
        <div @click="is_business_func()" :style="{color:'#0A6338',cursor:'pointer',marginTop:'-5%' ,marginLeft:'88%'}">
          X
        </div>
        <div :style="{width:'80%', marginLeft:'10%'}">
            Om detta är ditt företag så är du välkommen att skicka ett email till oss på info@enjord.com så hjälper vi er med registreringen
        </div>
        <button type="button" @click="to_mail()" :style="{background: '#0A6338 0% 0%', borderStyle:'none',  marginTop:'1%',color:'white',fontSize:'19px',width: '154px',height: '46px' ,borderRadius: '12px'}" name="button">
          Kontakta oss
        </button>
    </div>
    <div v-if="this.w >450" >
      <!-- <div :style="{display:'flex',height:'auto',width:'85%',marginLeft:'6.5%',marginTop:'1%',marginBottom:'20px', background: '#FCFCFC',borderRadius: '18px',boxShadow: '0px 4px 20px rgba(199, 199, 199, 0.6)'}"> -->
      <div :style="{height:'auto',width:'90%',marginLeft:'5%',marginTop:'1%',marginBottom:'20px'}">
        <div :style="{height:'auto',marginLeft:'15%',  display:'flex'}">
          <img  :src= "get_image(this.company_id,this.image)"
           :style="{height:'89px',marginLeft:'0%' ,objectFit:'contain',width:'89px',marginTop:'2%'}" alt="image">

          <div :style="{textAlign:'left', marginLeft:'2%'}">
            <div :style="{height:'30px',display:'flex', marginTop:'10%', textAlign:'center'}">
              <div :style="{  width:'auto',fontSize:'28px',height:'10px',letter:'-0.3px'}" >
                  {{this.name}}
              </div>
              <b-icon v-if="this.verified==1"   icon="patch-check-fill"
                :style="{color:'blue', marginLeft:'5px',marginTop:'4%', height:'20px',width:'20px'}"/>
            </div><br>
            <div v-if="this.contact_email != undefined" :style="{color:'#8A8A8A', fontWeight:'600', display:'flex'}">
              <img :src= "`${publicPath}`+ 'mail_.png'" class="addphoto_camera"  alt="" >
              <div :style="{marginLeft:'1%'}">
                {{this.contact_email}}
              </div>
            </div>
            <div v-if="this.phone != undefined" :style="{color:'#8A8A8A', fontWeight:'600', display:'flex'}">
              <img :src= "`${publicPath}`+ 'phone_.png'" class="addphoto_camera"  alt="" >
              <div class="">
                {{this.phone}}
              </div>
            </div>
            <div class="boxes">
              <div v-if="online_service" class="label_lev">
                Leverans över hela Sverige
              </div>
              <!-- <div class="label_fys">
                Fysisk butik
              </div> -->
            </div>
          </div>
            <div :style="{display:'flex', marginLeft:'25%', marginTop:'3%' ,height:'150px'}">
              <button  type="button" name="button" @click="open_site(company_website)" v-if="this.company_website != ' '"
               :style="{ cursor:'pointer', height:'30px', marginTop:'2%' , borderStyle:'none',color:'white',
                width:'140px',marginLeft:'2%',  backgroundColor:'#0A6338',borderRadius: '18px'}"  >
                Hemsida
               </button>
              <button v-if="this.verified==1" type="button" name="button" @click="choose_company()"
                :style="{ cursor:'pointer', height:'30px', marginTop:'2%', marginLeft:'2%' , borderStyle:'none',
                color:'white',width:'140px',
                  backgroundColor:'#0A6338',borderRadius: '18px'}"  >
                Kontakta oss
              </button>
            </div>
        </div>
     <div  :style="{ width:'100%',fontSize:'30px', marginTop:'2%',height:'auto',marginLeft:'0%'}">
        <!-- <div   :style="{fontSize:'14px', color:'grey', textAlign:'left'}">
          <div v-if="company_star_value==0">
              Inga recensioner
          </div>
          <div v-if="company_star_value!=0">
            <b-icon v-for="(i,idx) in company_star_value"  :key="idx" icon="star-fill"  :style="{color:'#FFED5F'}" />
            <b-icon v-for="(i,idx2) in company_star_value_grey"  :key="idx2" icon="star-fill"  :style="{color:'grey'}" />
            {{this.company_star_value_average}}
          </div>
        </div> -->
        <div :style="{ height:'auto',marginTop:'0%', fontSize:'15px', paddingBottom:'20px'}">
          <!-- <span :style="{color:'#8A8A8A', fontWeight:'600'}">Adress:</span>  {{this.address}} {{this.city_code}} {{this.company_city}}, {{this.country}} <br> -->
          <div :style="{backgroundColor:'#F2FFFC' ,paddingLeft:'1%', paddingBottom:'1%', paddingTop:'0%'}">
            <div v-if="this.about != ''"   class="header_white">
              Om oss:
            </div>
            <div  class="About_">
              {{this.about.trim()}};
            </div>
          </div>


          <div v-if="!!this.price  "
          class="header_white"          >
            Våra priser:
          </div>

          <div  class="About_" >
            {{this.price}}
          </div>

          <div v-if="!!this.our_services  " class="header_white">
            Våra tjänster:
          </div>

          <div  class="About_" >
            {{this.our_services}}
          </div>

          <div v-if="!!this.our_opening_hours" class="header_white">
            Våra öppettider:
          </div>
          <div  class="About_" >
            {{this.our_opening_hours}}
          </div>
          <div :style="{height:'20px'}">
          </div>

          <div v-if="lan_list_finns_i.length !=0"
            class="header_white">
            Här finns vi
          </div>
          <div :style="{width:'90%',marginLeft:'5%'}">
            <div v-for="(lan,idx) in lan_list_finns_i" :key="idx" >
              <div :style="{background: '#AFAFAF',fontWeight:'500',fontSize:'17px',marginTop:'0.2%'}" @click="open_lan_location(lan.i,lan.lan_id)">
                  {{lan.lan}}
              </div>

              <div v-if="lan.lan_opened" :style="{display:'flex', height:'auto', flexWrap:'wrap', maxWidth:'98%'}">
                <div v-for="(location,idd) in temp_locations_list" :key="idd" class="location_div" >
                  <div :style="{textAlign:'left', paddingLeft:'2%'}" >
                    <span :style="{color:'black', fontSize:'20px',fontWeight:'600'}">{{location.city}}</span><br>
                    {{location.adress}}<br> {{location.city_code}} {{location.city}}
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div v-if="brands_list.length !=0 " class="header_white">
            Här är de märken vi hanterar:
          </div>

          <div :style="{display:'flex', height:'auto',marginTop:'1%', flexWrap:'wrap', maxWidth:'98%'}">
              <div v-for="(brand,idd) in brands_list" :key="idd"
                   :style="{width:'100px',marginLeft:'5%',height:'30px'}" >
                <div >
                  {{brand.brand}}
                </div>
                <div :style="{borderStyle:'solid', borderWidth:'1px' }">
                </div>
              </div>
          </div>

          <!-- <div class="boxes">
                <div class="box_header">
                  Län vi finns i:
                </div>
                <div class="box_flow" v-for="(lan,idx) in lan_list" :key="idx">
                  {{lan.lan}},
                </div>
          </div> -->

          <div v-if="kategori_lista.length != 0 " class="header_white"  >
            Kategorier vi tillhör:
          </div>
          <div :style="{display:'flex', height:'auto',marginTop:'1%', flexWrap:'wrap',marginLeft:'5%', maxWidth:'90%'}">
              <div v-for="(kategori,idx) in kategori_lista" :key="idx" :style="{width:'auto',marginLeft:'2%' ,height:'30px'}" >
                <div >
                  {{kategori.category}},
                </div>
                <div :style="{borderStyle:'solid', borderWidth:'1px' }">
                </div>
              </div>
          </div>


          <!-- <div class="boxes">
                <div class="box_header">
                  Kategorier vi tillhör:
                </div>
                <div class="box_flow" v-for="(kategori,idx) in kategori_lista" :key="idx">
                  {{kategori.category}},
                </div>
          </div> -->


         </div>
        </div>
      </div>
      <div :style="{marginLeft:'5%'}">
        <div v-if=" gallery_image_list.length !=0 "  class="header_white">
          Galleri:
        </div>

      </div>

      <div v-if=" gallery_image_list.length !=0 "
      :style="{paddingBottom:'60px',width:'90%',paddingLeft:'2%',
      marginLeft:'2.5%',textAlign:'left'}">

        <!-- <div class="box_header_recension" >
          Galleri:
        </div> -->
        <div class="gallery_scroll">
          <div class="box_flow_images" data-toggle="tooltip" data-placement="top"
               title="Klicka på för att förstora"
               v-for="(image,idxx ) in gallery_image_list" :key="idxx">
            <img :src="get_image(company_id,image.image)"
                  @click="open_enlarge_image_box(image.image)"
                  class="show_chosen_image_first"  alt="image" >
          </div>
        </div>
      </div>

        <div :style="{boxShadow: '0px 4px 20px rgba(199, 199, 199, 0.6)',background: '#FCFCFC',borderRadius: '18px',paddingBottom:'60px',width:'85%',paddingLeft:'2%', marginLeft:'6.5%',textAlign:'left'}">
          <div class="box_header_recension" >
           Lämna en recension
          </div>
          <div :style="{textAlign:'left' ,marginLeft:'0%'}">
              <div class="">
                <div :style="{display:'flex',fontSize:'15px',}">
                  Ditt betyg: <button v-if="!star_chosen" @click="open_star_box()" type="button"
                  :style="{borderStyle:'none', color:'white',height:'20px',marginTop:'0%' , borderRadius:'8px', backgroundColor:'#0A6338'}"
                  name="button">Välj betyg <b-icon icon="star-fill"  :style="{color:'#FFED5F'}" /></button>
                  <div v-if="star_chosen" @click="open_star_box()" :style="{borderStyle:'none',color:'white', borderRadius:'8px'}">
                    <div v-if="star_value==1">
                      <b-icon icon="star-fill"  :style="{color:'#FFED5F'}" />
                    </div>
                    <div v-if="star_value==2">
                      <b-icon icon="star-fill"  :style="{color:'#FFED5F'}" /><b-icon icon="star-fill"  :style="{color:'#FFED5F'}" />
                    </div>
                    <div v-if="star_value==3">
                      <b-icon icon="star-fill"  :style="{color:'#FFED5F'}" /><b-icon icon="star-fill"  :style="{color:'#FFED5F'}" /><b-icon icon="star-fill"  :style="{color:'#FFED5F'}" />
                    </div>
                    <div v-if="star_value==4">
                      <b-icon icon="star-fill"  :style="{color:'#FFED5F'}" /><b-icon icon="star-fill"  :style="{color:'#FFED5F'}" /><b-icon icon="star-fill"  :style="{color:'#FFED5F'}" /><b-icon icon="star-fill"  :style="{color:'#FFED5F'}" />
                    </div>
                    <div v-if="star_value==5">
                      <b-icon icon="star-fill"  :style="{color:'#FFED5F'}" /><b-icon icon="star-fill"  :style="{color:'#FFED5F'}" /><b-icon icon="star-fill"  :style="{color:'#FFED5F'}" /><b-icon icon="star-fill"  :style="{color:'#FFED5F'}" /><b-icon icon="star-fill"  :style="{color:'#FFED5F'}" />
                    </div>
                  </div>
                </div>
                 <div v-if="star_box_opened"   :style="{backgroundColor:'white', borderRadius:'8px', boxShadow:' 0px 3px 30px #0000001A' ,height:'120px',width:'180px',marginLeft:'0%'}">
                   <div class="" @click="choose_star(1)">
                     <b-icon icon="star-fill"  :style="{color:'#FFED5F'}" />
                   </div>
                   <div class="" @click="choose_star(2)">
                     <b-icon icon="star-fill"  :style="{color:'#FFED5F'}" /><b-icon icon="star-fill"  :style="{color:'#FFED5F'}" />
                   </div>
                   <div class="" @click="choose_star(3)">
                   <b-icon icon="star-fill"  :style="{color:'#FFED5F'}" /><b-icon icon="star-fill"  :style="{color:'#FFED5F'}" /><b-icon icon="star-fill"  :style="{color:'#FFED5F'}" />
                   </div>
                   <div class="" @click="choose_star(4)">
                     <b-icon icon="star-fill"  :style="{color:'#FFED5F'}" /><b-icon icon="star-fill"  :style="{color:'#FFED5F'}" /><b-icon icon="star-fill"  :style="{color:'#FFED5F'}" /><b-icon icon="star-fill"  :style="{color:'#FFED5F'}" />&nbsp;&nbsp;
                   </div>
                   <div class="" @click="choose_star(5)">
                     <b-icon icon="star-fill"  :style="{color:'#FFED5F'}" /><b-icon icon="star-fill"  :style="{color:'#FFED5F'}" /><b-icon icon="star-fill"  :style="{color:'#FFED5F'}" /><b-icon icon="star-fill"  :style="{color:'#FFED5F'}" /><b-icon icon="star-fill"  :style="{color:'#FFED5F'}" /> &nbsp;&nbsp;
                   </div>
                 </div>
              </div>
          </div>
          <div :style="{textAlign:'left' ,fontSize:'15px', marginTop:'0.5%',marginLeft:'0%'}">
            <div :style="{display:'flex', height:'40px'}">
              Ditt namn &nbsp; <input type="text" :style="{borderRadius:'8px',height:'30px'}" v-model="reviewer_namn" name="" value="">
            </div>
          </div>
          <div :style="{width:'95%',textAlign:'left',fontSize:'15px', marginLeft:'0%'}">
            Din recension:<br>
            <textarea name="name" v-model="reviewer_review" rows="8" cols="80" :style="{height:'100px', whiteSpace: 'preWrap', width:'100%'}">
            </textarea>
          </div>
          <button type="button" @click="send_reviews()"  :style="{background: '#0A6338 0% 0%', borderStyle:'none',  marginTop:'0%',color:'white',marginLeft:'80%',width: '154px',height: '26px' ,borderRadius: '12px'}" name="button">
            Skicka recension
          </button>
          <div class="box_header_recension" >
            Alla recensioner
          </div>
          <div :style="{fontSize:'14px', color:'grey'}" v-if="review_list.length==0">
            Inga recensioner inlagda än
          </div>
          <div v-if="review_list.length !=0">
            <div  :style="{paddingBottom:'10px', marginTop:'2%',paddingTop:'0.8%',paddingLeft:'1%', width:'60%',color:'grey', boxShadow: '0px 0px 12px rgba(199, 199, 199, 0.6)',fontSize:'15px'}" v-for="(review,idx) in review_list" :key="idx">
              <div :style="{display:'flex'}">
                  <span :style="{fontWeight:550}">{{review.reviewer_name}}</span>
                <div >
                  <b-icon v-for="(i,idx) in review.nr_stars"  :key="idx" icon="star-fill"  :style="{color:'#FFED5F'}" />
                  <b-icon v-for="(i,idx2) in review.nr_stars_grey"  :key="idx2" icon="star-fill"  :style="{color:'grey'}" />
                </div>
              </div>
              <div class="review_class">
                {{review.review}}
              </div>
            </div>
          </div>
        </div>
    </div>


    <div v-if="this.w <=450" >
    <div    :style="{height:'auto',width:'100%'  ,marginTop:'0%',marginBottom:'20px'}">
      <!-- <div    :style="{height:'auto',width:'94%'  ,marginLeft:'3%',marginTop:'0%',marginBottom:'20px', background: '#FCFCFC',borderRadius: '18px',boxShadow: '0px 4px 20px rgba(199, 199, 199, 0.6)'}"> -->

      <div :style="{height:'120px',width:'100%'}" >
        <img :src= "get_image(this.company_id,this.image)"     :style="{height:'89px',marginLeft:'3%' ,objectFit:'contain',width:'89px',marginTop:'2%'}" alt="image">
      </div>

      <div :style="{fontWeight:'500',width:'90%', height:'auto',fontSize:'25px',marginTop:'0%',lineHeight:'32.5px',letter:'-0.3px'}" >
        {{this.name}}
        <b-icon v-if="this.verified==1"
          icon="patch-check-fill"
          :style="{color:'blue', marginLeft:'5px',position:'absolute',marginTop:'1.2%', height:'20px',width:'20px'}"/>
      </div>
      <div :style="{ height:'auto',marginTop:'0%', fontSize:'15px'}">

        <span v-if="this.contact_email != undefined"   :style="{color:'#8A8A8A', fontWeight:'600'}">
          <img :src= "`${publicPath}`+ 'mail_.png'" class="addphoto_camera"  alt="" >
        </span> {{this.contact_email}}<br>
        <span  v-if="this.phone != undefined" :style="{color:'#8A8A8A', fontWeight:'600'}" @click="call_phone()">
          <img :src= "`${publicPath}`+ 'phone_.png'" class="addphoto_camera"  alt="" >
        </span>  {{this.phone}}<br>
        <div class="boxes">
          <div v-if="online_service" class="label_lev">
            Leverans över hela Sverige
          </div>
          <!-- <div class="label_fys">
            Fysisk butik
          </div> -->
        </div>
      </div><br>
      <div >
        <div  :style="{ width:'95%',fontSize:'30px', marginTop:'1%',height:'auto',marginLeft:'4%'}">
           <!-- <div   :style="{fontSize:'14px', color:'grey', textAlign:'left'}">
             <div v-if="company_star_value==0">
                 Inga recensioner
             </div>
             <div v-if="company_star_value!=0">
               <b-icon v-for="(i,idx) in company_star_value"  :key="idx" icon="star-fill"  :style="{color:'#FFED5F'}" />
               <b-icon v-for="(i,idx2) in company_star_value_grey"  :key="idx2" icon="star-fill"  :style="{color:'grey'}" />
               {{this.company_star_value_average}}
             </div>
           </div> -->
        </div>
      </div>
      <div :style="{height:'50px'}">
        <button   type="button" name="button" @click="open_site(company_website)" v-if="this.company_website != ' ' "
         :style="{ fontSize:'15px',cursor:'pointer', height:'30px', marginTop:'0%' ,paddingLeft:'0.5%', paddingRight:'0.5%', borderStyle:'none',color:'white',width:'100px',  backgroundColor:'#0A6338',borderRadius: '18px'}"  >
          Hemsida
        </button>
        <!-- <button  v-if="this.verified==1" type="button" name="button" @click="choose_company()"
          :style="{ cursor:'pointer', fontSize:'15px',height:'65px', marginTop:'2%' , borderStyle:'none',color:'white',
          marginLeft:'1%', height:'30px',  backgroundColor:'#0A6338',borderRadius: '18px'}"  >
          Kontakta oss
        </button> -->
      </div>
      <div :style="{width:'100%'}">
        <div v-if="this.about != ''"  class="header_white">
          Om oss:
        </div>


        <div ref="About" v-bind:class="[this.isActive  ? 'About_' : '', 'About_extended']" >
          {{this.about}}
        </div>
        <div v-if="this.isActive && this.About_client_height <'50px'  "  class="show_more"
            @click="show_more_button()">
           ...Visa mer
        </div>
        <div v-if="!this.isActive && this.About_client_height < '50px' " class="show_more"
            @click="show_more_button()">
           ...Visa mindre
        </div>

        <div v-if="this.lan_list_finns_i.length !=0"  class="header_white">
          Här finns vi
        </div>
        <div v-for="(lan,idx) in lan_list_finns_i" :key="idx" >
          <div :style="{background: '#AFAFAF',marginTop:'1%'}" @click="open_lan_location(lan.i,lan.lan_id)">
              {{lan.lan}}
          </div>

          <div v-if="lan.lan_opened" :style="{display:'flex', height:'auto', flexWrap:'wrap', maxWidth:'98%'}">
            <div v-for="(location,idd) in temp_locations_list" :key="idd" class="location_div" >
              <div :style="{textAlign:'left', paddingLeft:'2%'}" >
                <span :style="{color:'black', fontSize:'18px',fontWeight:'600'}">{{location.city}}</span><br>
                {{location.adress}}<br> {{location.city_code}} {{location.city}}
              </div>
            </div>
          </div>

        </div>


        <div v-if="this.brands_list !=0" class="header_white">
          Här är de märken vi hanterar:
        </div>
        <div v-bind:class="[this.isActive_brand  ? 'brand_class' : '', 'brand_class_extended']" >
            <div v-for="(brand,idd) in brands_list" :key="idd"
            :style="{width:'100px',marginLeft:'5%',lineHeight:'20px' , paddingBottom:'5%',height:'auto'}" >
              <div >
                {{brand.brand}}
              </div>
              <div :style="{borderStyle:'solid', borderWidth:'1px' }">
              </div>
            </div>
        </div>
        <div v-if="this.brands_list !=0" class="show_more" @click="show_more_button_brand()">
           ...{{this.show_more_value}}
        </div>
        <div v-if="!!price" class="header_white">
          Våra priser:
        </div>

        <div ref="price"  v-bind:class="[this.isActive_price && !!this.price ? 'About_' : '', 'About_extended']">
          {{this.price}}
        </div>
        <div v-if="this.isActive_price && !!this.price &&
              (this.$refs.price.clientHeight < '50px')"  class="show_more"
            @click="show_more_button_price()">
           ...Visa mer
        </div>
        <div v-if="!this.isActive_price && !!this.price &&
             (this.$refs.price.clientHeight < '50px') " class="show_more"
            @click="show_more_button_price()">
           ...Visa mindre
        </div>

        <div v-if="!!our_services  " class="header_white">
          Våra tjänster:
        </div>
        <div ref="our_services" v-bind:class="[this.isActive_our_services && !!this.our_services ? 'About_' : '', 'About_extended']">
          {{this.our_services}}
        </div>

        <div v-if="this.isActive_our_services && !!this.our_services &&
                  (this.$refs.our_services.clientHeight < '50px'  )"  class="show_more"
              @click="show_more_button_our_services()">
           ...Visa mer
        </div>
        <div v-if="!this.isActive_our_services && !!this.our_services &&
                    (this.$refs.our_services.clientHeight < '50px'  )" class="show_more" @click="show_more_button_our_services()">
           ...Visa mindre
        </div>


        <div v-if="!!our_opening_hours" class="header_white">
          Våra öppettider:
        </div>
        <div  ref="opening_hours" v-bind:class="[this.isActive_opening_hours && !!this.our_opening_hours ? 'About_' : '', 'About_extended']">
          {{this.our_opening_hours}}
        </div>
        <div v-if="this.isActive_opening_hours && !!this.our_opening_hours &&
                   (this.$refs.opening_hours.clientHeight < '50px'  ) "  class="show_more"
            @click="show_more_button_opening_hours()" >
           ...Visa mer
        </div>
        <div v-if="!this.isActive_opening_hours && !!this.our_opening_hours &&
                   (this.$refs.opening_hours.clientHeight < '50px'  )" class="show_more"
            @click="show_more_button_opening_hours()" >
           ...Visa mindre
        </div>


        <!-- <div class="boxes">
              <div class="box_header">
                Län vi finns i:
              </div>
              <div class="box_flow" v-for="(lan,idx) in lan_list" :key="idx">
                {{lan.lan}},
              </div>
        </div><br>
        <div class="boxes">
              <div class="box_header">
                Kategorier vi tillhör:
              </div>
              <div class="box_flow" v-for="(kategori,idx) in kategori_lista" :key="idx">
                {{kategori.category}},
              </div>
        </div> -->

      </div>
    </div>

    <!-- <div v-if="this.verified==1"
     :style="{boxShadow: '0px 4px 20px rgba(199, 199, 199, 0.6)',background: '#FCFCFC',paddingBottom:'10px',marginTop:'14%',borderRadius: '18px',width:'94%',marginLeft:'3%',textAlign:'left'}">
     <contactform   :company_id="company_id" :verified="verified" from_page="company"   />
    </div> -->

    <div v-if=" gallery_image_list.length !=0 "   :style="{paddingBottom:'20px',width:'100%'}">
      <!-- <div v-if=" gallery_image_list.length !=0 " :style="{boxShadow: '0px 4px 20px rgba(199, 199, 199, 0.6)',background: '#FCFCFC',paddingBottom:'20px',borderRadius: '18px',width:'94%',marginLeft:'3%',textAlign:'left'}"> -->

      <div class="header_white">
        Galleri:
      </div>

      <!-- <div class="box_header_recension" >
      Galleri:

      </div> -->
      <div class="gallery_scroll">
        <div class="box_flow_images" data-toggle="tooltip" data-placement="top"
             title="Klicka på för att förstora"
             v-for="(image,idxx ) in gallery_image_list" :key="idxx"  :style="{marginLeft:'1%'}">
          <img :src="get_image(company_id,image.image)"
                @click="open_enlarge_image_box(image.image)"
                class="show_chosen_image_first"  alt="image" >
        </div>
      </div>
    </div>
<!-- :src="get_image(company_id,image.image)" -->

    <!-- <div    :style="{height:'auto',width:'94%',paddingLeft:'2%' , textAlign:'left',marginLeft:'3%',marginTop:'1%',marginBottom:'20px', background: '#FCFCFC',borderRadius: '18px',boxShadow: '0px 4px 20px rgba(199, 199, 199, 0.6)'}">
        <div class="box_header_recension" >
          Lämna en recension
        </div>
        <div :style="{textAlign:'left' ,marginLeft:'0%'}">
            <div class="">
              <div :style="{display:'flex',fontSize:'14px',}">
                Ditt betyg: <button v-if="!star_chosen" @click="open_star_box()" type="button"
                :style="{borderStyle:'none', color:'white',height:'20px',marginTop:'0%' , borderRadius:'8px', backgroundColor:'#0A6338'}"
                name="button">Välj betyg <b-icon icon="star-fill"  :style="{color:'#FFED5F'}" /></button>
                <div v-if="star_chosen" @click="open_star_box()" :style="{borderStyle:'none',color:'white', borderRadius:'8px'}">
                  <div v-if="star_value==1">
                    <b-icon icon="star-fill"  :style="{color:'#FFED5F'}" />
                  </div>
                  <div v-if="star_value==2">
                    <b-icon icon="star-fill"  :style="{color:'#FFED5F'}" /><b-icon icon="star-fill"  :style="{color:'#FFED5F'}" />
                  </div>
                  <div v-if="star_value==3">
                    <b-icon icon="star-fill"  :style="{color:'#FFED5F'}" /><b-icon icon="star-fill"  :style="{color:'#FFED5F'}" /><b-icon icon="star-fill"  :style="{color:'#FFED5F'}" />
                  </div>
                  <div v-if="star_value==4">
                    <b-icon icon="star-fill"  :style="{color:'#FFED5F'}" /><b-icon icon="star-fill"  :style="{color:'#FFED5F'}" /><b-icon icon="star-fill"  :style="{color:'#FFED5F'}" /><b-icon icon="star-fill"  :style="{color:'#FFED5F'}" />
                  </div>
                  <div v-if="star_value==5">
                    <b-icon icon="star-fill"  :style="{color:'#FFED5F'}" /><b-icon icon="star-fill"  :style="{color:'#FFED5F'}" /><b-icon icon="star-fill"  :style="{color:'#FFED5F'}" /><b-icon icon="star-fill"  :style="{color:'#FFED5F'}" /><b-icon icon="star-fill"  :style="{color:'#FFED5F'}" />
                  </div>
                </div>
              </div>
               <div v-if="star_box_opened"   :style="{backgroundColor:'white', borderRadius:'8px', boxShadow:' 0px 3px 30px #0000001A' ,height:'120px',width:'180px',marginLeft:'0%'}">
                 <div class="" @click="choose_star(1)">
                   <b-icon icon="star-fill"  :style="{color:'#FFED5F'}" />
                 </div>
                 <div class="" @click="choose_star(2)">
                   <b-icon icon="star-fill"  :style="{color:'#FFED5F'}" /><b-icon icon="star-fill"  :style="{color:'#FFED5F'}" />
                 </div>
                 <div class="" @click="choose_star(3)">
                 <b-icon icon="star-fill"  :style="{color:'#FFED5F'}" /><b-icon icon="star-fill"  :style="{color:'#FFED5F'}" /><b-icon icon="star-fill"  :style="{color:'#FFED5F'}" />
                 </div>
                 <div class="" @click="choose_star(4)">
                   <b-icon icon="star-fill"  :style="{color:'#FFED5F'}" /><b-icon icon="star-fill"  :style="{color:'#FFED5F'}" /><b-icon icon="star-fill"  :style="{color:'#FFED5F'}" /><b-icon icon="star-fill"  :style="{color:'#FFED5F'}" />&nbsp;&nbsp;
                 </div>
                 <div class="" @click="choose_star(5)">
                   <b-icon icon="star-fill"  :style="{color:'#FFED5F'}" /><b-icon icon="star-fill"  :style="{color:'#FFED5F'}" /><b-icon icon="star-fill"  :style="{color:'#FFED5F'}" /><b-icon icon="star-fill"  :style="{color:'#FFED5F'}" /><b-icon icon="star-fill"  :style="{color:'#FFED5F'}" /> &nbsp;&nbsp;
                 </div>
               </div>
            </div>
        </div>
        <div :style="{textAlign:'left' , marginTop:'0.5%',marginLeft:'0%'}">
          <div :style="{display:'flex', fontSize:'14px', height:'40px'}">
            Ditt namn &nbsp; <input type="text" :style="{borderRadius:'8px',height:'30px'}" v-model="reviewer_namn" name="" value="">
          </div>
        </div>
        <div :style="{width:'95%',textAlign:'left',fontSize:'14px', marginLeft:'0%'}">
          Din recension:<br>
          <textarea name="name" v-model="reviewer_review" rows="8" cols="80" :style="{height:'100px', whiteSpace: 'preWrap',width:'100%'}">
          </textarea>
        </div>
        <button type="button" @click="send_reviews()"  :style="{background: '#0A6338 0% 0%', borderStyle:'none',  marginTop:'0%',color:'white',marginLeft:'20%',width: '154px',height: '26px' ,borderRadius: '12px'}" name="button">
          Skicka recension
        </button>
        <div class="box_header_recension" >
         Alla recensioner
        </div>
        <div :style="{fontSize:'14px', color:'grey'}" v-if="review_list.length==0">
         Inga recensioner inlagda än
        </div>
        <div  v-if="review_list.length !=0">
          <div :style="{paddingBottom:'10px', marginTop:'2%',paddingTop:'0.8%',paddingLeft:'1%', color:'grey',boxShadow: '0px 0px 12px rgba(199, 199, 199, 0.6)', fontSize:'15px'}" v-for="(review,idx) in review_list" :key="idx">
            <div :style="{display:'flex'}">
              <span :style="{fontWeight:550}">{{review.reviewer_name}}</span>
              <div >
                <b-icon v-for="(i,idx) in review.nr_stars"  :key="idx" icon="star-fill"  :style="{color:'#FFED5F'}" />
                <b-icon v-for="(i,idx2) in review.nr_stars_grey"  :key="idx2" icon="star-fill"  :style="{color:'grey'}" />
              </div>
            </div>
            <div class="review_class">
             {{review.review}}
            </div>
          </div>
        </div>
      </div> -->


    </div>
  </div>
</template>

<script>
 //import Navigation from '../components/Navigation.vue';
 import router from '../router/index'
 import axios from 'axios';
import {bus} from '../main'
 import contactform from '../components/contact_form.vue';
 import {API_URL} from '../../config.js'
export default {
  data:function(){
    return{
      star_box_opened:false,
      review_box_opened:false,
      publicPath: process.env.BASE_URL +'',
      kategori_lista:[],
      name:'',
      address:'',
      contact_email:'',
      city_code:'',
      our_opening_hours:'',
      id:0,
      nr_stars:0,
      match:0,
      image:'',
      about:'',
      online_service:'',
      our_services:'',
      price:'',
      is_business:false,
      current_user:[],
      company_city:'',
      company_website:'',
      country:'',
      About_client_height:'',
      lan_list:[],
      products_list:[],
      verified:0,
      company_id:0,
      from_page:'',
      w:0,
      isHovered:false,
      star_chosen:false,
      star_value:0,
      company_star_value:0,
      company_star_value_grey:0,
      phone:"",
      reviewer_namn:"",
      reviewer_review:"",
      review_list:[],
      company_star_value_average:0,
      company_star_value_total:0,
      chosen_company_ids:[],
      checked:false,
      shown_image_flag:false,
      shown_image:'',
      show_more_value:'Visa mer',
      isActive:true,
      isActive_brand:true,
      isActive_opening_hours:true,
      isActive_our_services:true,
      isActive_price:true,
      gallery_image_list:[],
      temp_locations_list:[],
      lan_list_finns_i:[],
      //   // {lan_id:0, lan:"Skåne län", lan_opened:false},
      //   // {lan_id:1, lan:"Stockholms län", lan_opened:false},
      //   // {lan_id:2, lan:"Västra Götalands län", lan_opened:false},
      // ],
      brands_list:[],
    }
  },
  watch: {
    "$route.params.id"() {
      this.company_id= this.$route.query.id
      this.get_company_information()
    },
  },
  methods:{
    call_phone(){
      window.open('tel:' + this.phone)
    },
    show_more_button(){
      if(this.isActive){
        this.isActive = false
      }
      else{
        this.isActive = true
      }
    },
    show_more_button_brand(){
      if(this.isActive_brand){
        this.show_more_value = 'Visa mindre'
        this.isActive_brand = false
      }
      else{
        this.show_more_value = 'Visa mer'
        this.isActive_brand = true
      }
    },
    show_more_button_opening_hours(){

      if(this.isActive_opening_hours){
        this.isActive_opening_hours = false
      }
      else{
        this.isActive_opening_hours = true
      }
    },
    show_more_button_our_services(){
      if(this.isActive_our_services){
        this.isActive_our_services = false
      }
      else{
        this.isActive_our_services = true
      }
    },
    show_more_button_price(){
      if(this.isActive_price){
        this.isActive_price = false
      }
      else{
        this.isActive_price = true
      }
    },
    open_lan_location(i,lan_id){
      if(this.lan_list_finns_i[i].lan_opened){
        this.lan_list_finns_i[i].lan_opened = false
      }
      else {
        this.lan_list_finns_i[i].lan_opened = true
        for (var ii = 0; ii < this.lan_list_finns_i.length; ii++) {
          if(this.lan_list_finns_i[ii].lan_id != lan_id){
            this.lan_list_finns_i[ii].lan_opened = false
          }
        }


        var self = this
        axios({
            method:'get',
            url: API_URL+ '/get_company_locations'+'?lan_id='+lan_id+'&company_id='+this.company_id,
          })
          .then(function(response){
                self.temp_locations_list = response.data
          })

      }





      // for (var i = 0; i < this.locations_list.length; i++) {
      //   if(this.locations_list[i]["lan_id"] ==id){
      //     this.temp_locations_list =this.locations_list[i].location_lista
      //   }
      // }


    },

    open_enlarge_image_box(image){
      this.shown_image_flag = true
      this.shown_image=image
    },

    to_result(){
      router.go(-1)
  // router.push({ path:"result"})
  // if(this.from_page == 'category'){
  //   router.push({ path:"result" ,query:{ from_page:this.from_page, category_id:this.$route.query.category_id}})
  // }
  // else{
  // router.push({ path:"result" ,query:{ from_page:this.from_page}})
  // }

    },
    choose_company(){
        if(!this.checked){
          this.chosen_company_ids.push(this.company_id)
          this.checked = true
          axios({
            method:'post',
            url: API_URL+ '/click_company_lead_button',
            data:{'company_id':this.company_id}
          })
          .then(function(){
           })
        }
        else {
          this.chosen_company_ids=[]
          this.checked = false

        }
    },
    get_reviews(){
      var self = this
      axios({
          method:'get',
          url: API_URL+ '/get_reviews'+'?company_id='+this.company_id,
        })
        .then(function(response){
            self.review_list=response.data["show_review_info_list"]
            self.company_star_value_total =response.data["review_star_sum"]
            self.calculate_review_score()
        })
    },
    calculate_review_score(){
      this.company_star_value_total = this.company_star_value_total+this.star_value
      var company_star_value_average
      if (this.review_list.length ==0){
         company_star_value_average=0
      }
      else{
        company_star_value_average =this.company_star_value_total/this.review_list.length
      }
      this.company_star_value_average = Math.round(company_star_value_average * 100) / 100


      if(this.company_star_value_average< 0.5 ){
        this.company_star_value =0
        this.company_star_value_grey =5
      }else if (this.company_star_value_average< 1.5) {
        this.company_star_value =1
        this.company_star_value_grey =4
      }else if (this.company_star_value_average< 2.5) {
        this.company_star_value =2
        this.company_star_value_grey =3
      }
      else if (this.company_star_value_average< 3.5) {
        this.company_star_value =3
        this.company_star_value_grey =2
      }
      else if (this.company_star_value_average< 4.5) {
        this.company_star_value =4
        this.company_star_value_grey =1
      }else {
        this.company_star_value =5
        this.company_star_value_grey =0
      }

    },
    send_reviews(){
      var new_id = this.review_list.length


      var review_list =[{
        "id":new_id,
        "nr_stars":this.star_value,
        "reviewer_name":this.reviewer_namn,
        "review":this.reviewer_review
      }]
      // this.review_list.push({
      //   "id":new_id,
      //   "nr_stars":this.star_value,
      //   "reviewer_name":this.reviewer_namn,
      //   "review":this.reviewer_review
      // })


      var self = this
      axios({
         method:'post',
         url: API_URL+ '/add_review',
         data:{company_id:this.company_id ,review_list:review_list,company_star_total:this.company_star_value_total}
       })
       .then(function(){
         self.review_box_opened = false
         self.star_chosen =false
         self.reviewer_namn=""
         self.reviewer_review=""
         self.star_value =0
         self.get_reviews()
       })
    },
    open_star_box(){
      if(this.star_box_opened){
        this.star_box_opened = false
      }else{
        this.star_box_opened = true
      }
    },
    choose_star(star_value){
      this.star_chosen = true
      this.star_value = star_value
      this.star_box_opened = false
    },
    omdöme_func(){
      if(this.review_box_opened){
        this.review_box_opened = false
      }
      else{
        this.review_box_opened = true
      }
    },
    get_image(id,image){
      if (image){
        var data = 'enjordrepair_user' + id + "/" + image
        return this.$func.get_image(data)
      }
    },
    is_business_func(){
      if(this.is_business){
        this.is_business =false
      }else{
        this.is_business = true
      }
    },
    to_company(id){
          this.$forceUpdate()
        router.push({ path:"company_page", query:{id:id} })
    },
    open_site(website){
//      window.location.href=website
      window.open(website, '_blank').focus();
        axios({
          method:'post',
          url: API_URL+ '/click_website',
          data:{'company_id':this.company_id}
        })
        .then(function(){
         })
  },
  to_mail(){
    window.location.href="mailto:info@enjord.com?Subject=Detta är mitt företag @Enjord:"
  },

  get_company_information_lan(){
    var self = this
    axios({
        method:'get',
        url: API_URL+ '/get_company_information_lan'+'?company_id='+this.company_id,
      })
      .then(function(response){
        //self.lan_list = response.data["show_lan_check_list"]

        self.lan_list_finns_i = response.data["lan_we_are_in_list"]
      })
    },
    remove_flag_enlarge(){
      this.shown_image_flag = false

    },
    get_company_information(){
      var self = this
      axios({
          method:'get',
          url: API_URL+ '/get_company_information'+'?company_id='+this.company_id,
        })
        .then(function(response){

          self.name = response.data["show_company_info"]["name"]
          self.image= response.data["show_company_info"]["image"]
          self.about= response.data["show_company_info"]["about"]
          self.verified= response.data["show_company_info"]["verified"]
          self.online_service= response.data["show_company_info"]["online_service"]



          self.company_website= response.data["show_company_info"]["website"]
          self.contact_email =response.data["show_company_info"]["contact_email"]

          self.phone =response.data["show_company_info"]["phone_number"]
          self.our_services =response.data["show_company_info"]["our_services"]
          self.our_opening_hours =response.data["show_company_info"]["our_opening_hours"]

          self.price =response.data["show_company_info"]["price"]
          self.company_star_value_total =response.data["show_company_info"]["company_star_value_total"]

          self.brands_list = response.data["brands_list"]
          // self.address =response.data["show_company_info"]["adress"]
          // self.city_code =response.data["show_company_info"]["city_code"]
          // self.company_city =response.data["show_company_info"]["company_city"]

          self.country =response.data["show_company_info"]["country"]

          self.kategori_lista=response.data["show_category_list"]
          self.gallery_image_list=response.data["show_images_list"]
          self.get_company_information_lan()
          self.get_reviews()
        })
    }
  },
  mounted(){
    this.About_client_height = this.$refs.About.clientHeight
    window.scrollTo(0, 0);
   this.w = window.screen.width
    this.company_id= this.$route.query.id
    this.current_user = this.$store.state.current_user

    bus.$on('close_contact_form', (val) => {
      this.checked = val
    })
    // this.gallery_image_list = [
    //   {"image": "recycle.png" },
    //   {"image": "repariflat.jpg"},
    //   {"image": "ukelele1.jpg"},
    //   {"image": "ukelele2.jpg"}
    // ]

    this.get_company_information()


  },
  components:{
    //Navigation,
     contactform

    //StarRating
  }
}
</script>

<style lang="css" scoped>
.star_filled{
    color: yellow
}

.star_empty{
    color: grey
}

.review_class{
  white-space: pre;
  white-space: pre-wrap;
  height: auto;

}

.label_boxes{
  height:auto;
  position: absolute;
  /* margin-left: 11.5%; */
  display:flex;
  overflow-x: auto;
  margin-top: 0%;

  /* scrollbar-width:thin; */
  width: 22%;
}
.label_lev{
  border-radius: 18px;
  background-color: blue ;
  height: auto;
  color: white;
  padding-left: 1%;
  padding-right:  1%;
  padding-top: 0%;
  padding-bottom: 0%;
  margin-left: 1%;
  font-size: 13px;
  display: inline-block;
  flex: none;
  box-sizing: border-box;
}

.label_fys{
  border-radius: 18px;
  background-color: green ;
  height: auto;
  color: white;
  padding-left: 1%;
  padding-right:  1%;
  padding-top: 0%;
  padding-bottom: 0%;
  margin-left: 1%;
  font-size: 13px;
  display: inline-block;
  flex: none;
  box-sizing: border-box;
}


.location_div{
  border-radius:  9px;
  object-fit:  contain;
  background: #D9D9D9;
  min-width:  48%;
  max-width:  48%;
  margin-left: 1%;
  margin-top: 1%;
  padding-bottom: 1%;

  height: 99px;
  display: flex;
}


/* .btn-iconed:hover  {
   left: 0px;
} */

@media (max-width:450px ){

  .header_color{
    background-Color:#0A6338;
    width: 100%;
    height: 37px;
    color:white;
    font-Weight:600

  }

  .header_white{
    margin-top: 6%;
    width: 100%;
    height: 37px;
    color:black;
    font-size: 20px;
    font-Weight:600

  }

  .gallery_scroll{
    width:98%;
    overflow-x: scroll;
     display:flex;
     height: 80px;
  }

.brand_class{
  display:flex;
  max-height: 100px;
  flex-Wrap:wrap;
  max-Width:98%;
  overflow-y: hidden;
  padding-Bottom:25px;
}


  .brand_class_extended{
    display:flex;
    height:auto;
    flex-Wrap:wrap;
    max-Width:98%;
    padding-Bottom:25px;

  }

  .show_more{
      font-weight: bold;
  }

  .box_header_recension{
    height: 35px;
    flex: none;
    box-sizing: border-box;
    font-size: 15px;
    color:#8A8A8A;
    padding-top: 3%;
    padding-left: 4%;
    font-Weight:600;
    margin-top:4%;
  }

  .box_flow_images{
    color: grey;
    height:60px;
    max-width: 80px;
    margin-top: 3%;

    /* overflow-x: auto; */
  /* width: auto; */
  /* display: flex; */
  }

  .show_chosen_image_first{
    height:60px;
    width: 80px;
      border-radius: 8px;
    object-fit: contain;
    background-color: white;
  cursor: pointer;
  }

  .About_{
    /* text-align: left; */
    /* min-height: 30px; */
    text-align: center;
    height: auto;
    /* max-height: 150px; */
    overflow-y: auto;
    margin-top: 2%;
    /* box-shadow: 0px  1px 1px rgba(199, 199, 199, 0.6); */
    /* overflow-y: auto; */
    width: 98%;

    padding-bottom: 18px;
    /* display:flex; */
    margin-top:0%;
    margin-left: 2%;
    /* text-align: left; */
    color: #646464 ;
    max-height: 80px;
    overflow-y: hidden;
    font-style: normal;
    font-weight: 400;
    font-size: 17px;
    line-height: 23px;
    letter-spacing: -0.3px;
    /* max-height: 130px; */
  }


  .About_extended{
    text-align: center;
    overflow-y: auto;
    margin-top: 2%;
    width: 98%;
    padding-bottom: 18px;
    margin-top:0%;
    margin-left: 2%;
    color: #646464 ;
    font-style: normal;
    font-weight: 400;
    font-size: 17px;
    line-height: 23px;
    letter-spacing: -0.3px;

  }


}

@media (min-width:450px ){


  .header_white{
    width: 100%;
    height: 37px;
    color:black;
    text-Align:left;
    margin-top: 3%;
    font-Size:24px;
    font-Weight:600
  }

  .header_color{
      background-Color:#0A6338;
      margin-Top:2%;
      width: 100%;
      height: 37px;
      color:white;
      font-Weight:600
  }


  .gallery_scroll{
    width:98%;
    overflow-x: scroll;
     display:flex;
  }



  .box_flow_images{
    margin-left: 1%;
    color: grey;
    height: 150px;
    width: 140px;
    /* overflow-x: auto; */
  /* width: auto; */
  /* display: flex; */
  }


  .show_chosen_image_first{
   height: 100%;
      border-radius: 8px;
    width: 100%;
    object-fit: contain;
    background-color: white;
  cursor: pointer;
  }

  .About_{
    /* min-height: 30px; */
    height: auto;
    margin-top: 2%;
    text-align: left;

   /* scrollbar-width: thin; */
    overflow-y: auto;
    /* box-shadow: 0px  1px 1px rgba(199, 199, 199, 0.6); */
    width: 98%;
    white-space: pre;
    white-space: pre-wrap;
    display:flex;
    margin-top:0%;
    margin-left: 2%;
    text-align: left;
    color: #646464 ;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 23px;
    letter-spacing: -0.3px;
    max-height: 150px;
  }

}

.flow{
  height: auto;
  width: 92%;
  display: flex;
  margin-left: 4.2%;
  margin-top: 3%;
  flex-wrap: wrap;
  padding-bottom: 60px;
}

.flowpost{

  height: 152px;
  object-fit: contain;
  min-width: 289px;
  max-width: 289px;
  /* min-width: 142px;
  max-width: 142px; */
  display: flex;
  margin-top: 1%;
  /* padding: 1%; */
  margin-left: 2%;
  padding-bottom: 0px;
  border: 1px solid #C5C2BE;

  border-radius: 14px;

}

.boxes{
   height:auto;
   padding-bottom: 0%;
   margin-left: 0%;
    /* display:flex; */
    overflow-x: auto;
    margin-top: 1%;
 width: 90%;

}

.box_flow{
  margin-left: 0%;
  color: grey;
  height: auto;
  /* overflow-x: auto; */
/* width: auto; */
display: inline-block;
flex: none;
box-sizing: border-box;
font-size: 15px;
}


.flow_products{
  height: auto;
  width: 92%;
  display: flex;
  margin-left: 4%;
  margin-top: 3%;
  flex-wrap: wrap;
  padding-bottom: 60px;
}

.box_header_recension{
  height: 35px;
  flex: none;
  box-sizing: border-box;
  font-size: 15px;
  color:#8A8A8A;
  padding-top: 1%;
  font-Weight:600;
  margin-top:4%;
}


.box_header{
  height: 20px;
  flex: none;
  box-sizing: border-box;
  font-size: 15px;
  color:#8A8A8A;
font-Weight:600
}

</style>
